.App {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

button {
  border-radius: 50%; /* Makes the button round */
  width: 200px;
  height: 200px;
  background-image: url('glass.png'); /* Replace with the actual image path */
  background-size: cover; /* Ensures the image covers the button */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  transition: all 0.4s ease;
  background-color: transparent;
  border: none;
  
}

.recording-button {
  background-image: url('glass-2.png');
  transition: all 0.4s ease 0s;
}
.generate {
  background-image: url('glass-3.png');
  transition: all 0.4s ease 0s;
}
.otvet{
  background-image: url('glass-4.png');
  transition: all 0.4s ease 0s;
}
.display{
  width: 300px;
  height: 300px;
  background-image: url('button-image-5.png');
  background-size: cover; /* Ensures the image covers the button */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  display: flex;
  justify-content: center;
  align-items: center;
}
.display:active{
  box-shadow: none;
	transform: translate(10px, 10px);
}
/* В файле App.css или index.css */

.StatusMessage {
  text-align: center;
  margin-top: 20px;
  min-height: 95px;
  min-width: 10px;
}

.StatusMessage h2 {
  font-size: 82px;
  font-family: "Radar";
  font-weight: normal;
}
.StatusButton h2 {
  font-size: 32px;
  font-family: "Radar";
  font-weight: normal;
}

.send-telegram{
  font-size: 32px;
  font-family: "Radar";
  font-weight: normal;
}

.StatusMessage h2.show {
  opacity: 1;
  transform: translateY(0);
}

.StatusMessage h2.hide {
  opacity: 0;
  transform: translateY(-20px);
}