@import './variables.scss';
@import './fonts.scss';
@import 'swiper/css';
@import 'swiper/css/bundle';

* {
	box-sizing: border-box;
}

.work {
	background-color: #fff;
}

:root {
	--primary-color: #472df8;
	--secondary-color: #ffffff;
	--tg-theme-bg-color: #472df8;
	--tg-theme-text-color: #000000;
	--tg-theme-hint-color: #8E8E93;
	--tg-theme-link-color: #007AFF;
	--tg-theme-button-color: #007AFF;
	--tg-theme-button-text-color: #FFF;
	--tg-theme-secondary-bg-color: #EFEFF4;
}

#root {
	margin: 0 auto;
	max-width: $container-max-width;
}

// button {
// 	background-color: inherit;
// 	padding: 0;
// 	cursor: pointer;
// 	box-shadow: 10px 10px;
//     transition: all 0.1s ease-out;
// }
// button:active{
// 	box-shadow: none;
// 	transform: translate(10px, 10px);
// }

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

a {
	text-decoration: none;
}

.content {
	min-height: 100vh;
	background-color: #ffffff;
}

.center {
	margin: 0 auto;
}

.container {
	max-width: $container-max-width;
	overflow-y: hidden;
	margin: 0 auto;
	// padding: 0 16px;
	background-color: var(--tg-theme-secondary-bg-color);
}

.padding{
	padding: 0 16px;
}

.title {
	width: 100%;
	color: var(--tg-theme-text-color);
	font-family: 'SF Pro Display';
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 32px; /* 133.333% */
	letter-spacing: 0.38px;
}

.subtitle {
	color: var(--tg-theme-text-color);
	text-align: center;
	font-family: 'SF Pro Display';
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 14px; /* 100% */
	letter-spacing: -0.078px;
}
.subtitle-text {
	color: var(--tg-theme-hint-color);
	font-family: 'SF Pro Display';
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 14px; /* 116.667% */
	letter-spacing: -0.154px;
}

address {
	font-style: normal;
}

.swiper{
	margin-left: unset;
	margin-right: unset;
	padding-left: 16px;
	padding-right: 16px;
}

.swiper-slide {
	display: flex;
	align-items: flex-end;
	// width: 130px!important;
}

.swiper-wrapper {
	align-items: flex-start;
	// padding-left: 15px;
	height: 320px;
	margin-bottom: 25px;
}

.swiper-pagination{
	display: flex;
	padding: 8px 12px;
	background-color: rgba(116, 116, 128, 0.08);
	width: fit-content!important;
	border-radius: 12px;
	left: calc(50% - 44px)!important;
	bottom: var(--swiper-pagination-bottom, 30px)!important;
}

.swiper-pagination-bullet{
	width: 8px;
	height: 8px;
	border-radius: 4px;
	opacity: 0.3;
	background: var(--tg-theme-hint-color);
}

.swiper-pagination-bullet-active{
	opacity: 1;
}

.category-module__item-one {
	background-color: red;
}

.pos {
	&-start {
		align-items: start;
	}

	&-center {
		align-items: center;
	}

	&-end {
		align-items: end;
	}
	&-left {
		justify-items: start;
	}

	&-right {
		justify-items: end;
	}
}

.block {
	display: flex;
	padding: 12px;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	align-self: stretch;
	border-radius: 16px;
	background: var(--tg-theme-bg-color);
}

.grid {
	&-one {
		display: flex;
		width: 100%;
		height: 100%;
		flex-direction: column;
		align-items: center;
		gap: 12px;
		padding-bottom: 25px
	}

	&-two {
		display: flex;
		width: 100%;
		height: 100%;
		flex-direction: column;
		align-items: center;
		gap: 24px;
		padding-bottom: 25px
	}
	&-three {
		display: flex;
		width: 100%;
		height: 100%;
		flex-direction: column;
		align-items: center;
		gap: 24px;
		padding-bottom: 25px
	}
}

.blink {
	animation: blink 2s;
}
.unblink {
	animation: unblink 2s;
}

.blinking {
	animation: blinking 6s;
}

@keyframes blink {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}

@keyframes unblink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 0;
	}
}

@keyframes blinking {
	0% {
		opacity: 0;
	}
	25% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
