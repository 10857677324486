/* Общий стиль для приложения */
.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  background-color: #D3D0E5;
  color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 20px;
  background-image: url('Background.png');
  background-size: cover; /* This ensures the image covers the entire background */
  background-position: center; /* This centers the background image */
  background-repeat: no-repeat; /* Prevents the background image from repeating */
}


.wrap h2{
  font-size: 16px;
  font-weight: 400;
}
.StatusMessage{
  margin-bottom: 20px;
}

body{
  font-family: 'PTSerif';
}

/* Логотип приложения */
.App-logo {
  height: 40vmin;
  pointer-events: none;
  transition: transform 0.5s ease-in-out;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 15s linear;
  }
}

/* Основной заголовок */
.App-header {
  background-color: #282c34;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  font-size: calc(12px + 2vmin);
}

/* Ссылка приложения */
.App-link {
  color: #61dafb;
  text-decoration: none;
  font-weight: 500;
  margin-top: 20px;
  transition: color 0.3s ease;
}

.App-link:hover,
.App-link:focus {
  color: #21a1f1;
  text-decoration: underline;
}

/* Стиль для скрытого элемента, который можно показать при необходимости */
.MUIC {
  display: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Дополнительные улучшения для мобильных устройств */
@media (max-width: 768px) {
  .App-header {
    font-size: calc(10px + 2vmin);
    padding: 10px;
  }

  .App-logo {
    height: 30vmin;
  }
}
.play-button{
  border-radius: 50%; /* Makes the button round */
  width: 208px;
  height: 208px;
  background-image: url('glass-4.png'); /* Replace with the actual image path */
  background-size: cover; /* Ensures the image covers the button */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  transition: all 0.4s ease;
  background-color: transparent;
  border: none;
  transition: all 0.4s ease 0s;
}
.play-button h2{
  margin-right: -7px;
}
/* .play-button:active{
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24); 
  transform: scale(0.80); 
} */
/* Дополнительные улучшения для крупных экранов */
@media (min-width: 1200px) {
  .App-header {
    font-size: calc(14px + 2vmin);
  }

  .App-logo {
    height: 50vmin;
  }
}


.wrap {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 10px; /* Отступ сверху */
  right: 40px; /* Отступ справа */
  align-items: center;
  gap:5px;
  font-family: "Garamond";
}

.wallet {
  width: 46px;
  height: 46px;
  position: relative;
}

.icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  position: relative;
  z-index: 101;
}

.coin {
  position: absolute;
  top: var(--coin-from-x, 24px);
  left: var(--coin-from-y, 24px);
  z-index: 100;
  opacity: 0;
}

.coin::after {
  content: "$";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  font-size: 10px;
  color: rgb(237, 196, 107);
  background: rgb(227, 162, 23);
  border: 2px solid rgb(237, 196, 107);
  border-radius: 50%;
  opacity: 0;
}

.coin--animated,
.coin--animated::after {
  animation-delay: var(--coin-delay, 0s);
  animation-duration: var(--coin-duration, 1.5s);
  animation-direction: normal;
  animation-fill-mode: both;
  animation-play-state: running;
  animation-iteration-count: infinite;
}

.coin--animated {
  animation-name: coin-x-axis;
  animation-timing-function: ease-in;
}

.coin--animated::after {
  animation-name: coin-y-axis-and-flip;
  animation-timing-function: ease-out;
}

@keyframes coin-x-axis {
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  to {
    left: calc(var(--coin-to-x) * 1.5);
  }
}

@keyframes coin-y-axis-and-flip {
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  to {
    transform: translateY(calc(var(--coin-to-y) * 1.5)) rotate3d(1, 1, 1, 1080deg);
  }
}
/* .display{
  opacity: 0;
  animation: ani 4.5s forwards;
} */
button{
  opacity: 0;
  animation: ani 0.5s forwards;
}
.play-button{
  opacity: 0;
  animation: ani 0.5s forwards;
}
div{
  opacity: 0;
  animation: ani 0.5s forwards;
}
h2{
  opacity: 0;
  animation: ani 0.5s forwards;
}
@keyframes ani {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.coin-enter {
  opacity: 0;
  transform: translateY(-20px);
}
.coin-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms, transform 500ms;
}
.coin-exit {
  opacity: 1;
  transform: translateY(0);
}
.coin-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 500ms, transform 500ms;
}

.text-enter {
  opacity: 0;
  transform: translateY(-20px);
}
.text-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms, transform 500ms;
}
.text-exit {
  opacity: 1;
  transform: translateY(0);
}
.text-exit-active {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 500ms, transform 500ms;
}